<template>
	<v-row>
        <v-dialog
        v-model="dialog"
        persistent
        width="600"
        scrollable=false
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
                <div class="text-h6 title">Оставьте контактные данные, чтобы первыми</div>
                <div class="text-h6 title">узнавать о полезных функциях и обновлениях</div>
            </v-col>
            <v-col cols="1">
                <v-btn icon @click="submitData('hide')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-col>
          </v-card-title>
          <div class="hr">
              <hr>
          </div>
          <v-card-text>
            <v-container>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="6" class="input-block">
                    <p class="Label">Имя*</p>
                    <v-text-field
                        required
                        outlined
                        v-model="firstname"
                        :rules="nameRules"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" class="input-block">
                    <p class="Label">Фамилия*</p>
                    <v-text-field
                        required
                        outlined
                        v-model="lastname"
                        :rules="nameRules"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" class="input-block">
                    <p class="Label">Компания*</p>
                    <v-text-field
                        required
                        outlined
                        v-model="company"
                        :rules="nameRules"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" class="input-block">
                 <p class="Label">Email*</p>
                  <v-text-field
                    required
                    outlined
                    v-model="email"
                    :rules="emailRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="input-block">
                <p class="Label">Телефон*</p>
                  <v-text-field
                    required
                    outlined
                    maxlength=15
                    v-model="phone"
                    :rules="phoneRules"
                    @input="acceptNumber"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="input-block checkbox">
                  <v-checkbox v-model="agree">
                    <template v-slot:label>
                      <div>
                        Я соглашаюсь получать рекламно-информационные материалы в соответствии с 
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a target="_blank" href="https://www.mcart.ru/conf/" @click.stop v-on="on">
                              Соглашением
                            </a>
                          </template>
                          Открыть соглашение в новой вкладке
                        </v-tooltip>
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-form>
            </v-container>
          </v-card-text>
          <div class="hr">
              <hr>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="#c8c8c8"
              variant="text"
              @click="submitData('not_send')"
            >
              Нет, спасибо
            </v-btn>
            <v-btn
              depressed
              color="primary"
              variant="text"
              @click="submitData('send')"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
		
	</v-row>
</template>

<script>
import Vue from "vue";
const axios = require('axios')

export default {
    name: "ContactForm",
    data() {
        return {
            dialog: true,
            valid: true,
            firstname: '',
            lastname: '',
            company: '',
            email: '',
            phone: '',
            agree: true,
            nameRules: [
                value => {
                    if (value) return true

                    return false
                }
            ],
            emailRules: [
                value => {
                    if (value) return true

                    return false
                },
                value => {
                    if (/.+@.+\..+/.test(value)) return true

                    return 'Некорректный E-mail'
                },
            ],
            phoneRules: [
                value => {
                    if (value) return true

                    return false
                  },
            ],
        }
    },
    methods: {
        submitData(type) {
            const BX24 = window['BX24'];
            var self = this;

            BX24.init(function() {

                let can_send = true;
                let params = {}
                
                if (type == 'send') {
                  can_send = self.$refs.form.validate()
                }

                if (can_send) {
                self.dialog = false;
                let is_send = false;
                let bitrix_user = false;
                
                BX24.callMethod('user.current', {}, function(res) {
                    if ('ID' in res.data()) {
                        bitrix_user = res.data().ID;
                    }

                    const domain = BX24.getDomain();

                    BX24.callMethod('app.info', {}, function(app_result) {
                      var tariff = '';
                      
                      if ('LICENSE' in app_result.data()) {
                        tariff = app_result.data()['LICENSE']
                      }

                      if (type == 'send') {
                          params = {
                              firstname: self.firstname, 
                              lastname: self.lastname,
                              company: self.company, 
                              email: self.email, 
                              phone: self.phone.replace(/[^0-9]/g, ''),
                              agree: self.agree,
                              send: true,
                              domain: domain,
                              current_user: bitrix_user,
                              tariff: tariff
                          }
                          self.sendForm(params)

                          is_send = true
                      } else if (type == 'not_send') {
                          params = {
                              domain: domain,
                              current_user: bitrix_user,
                              send: false
                          }
                          self.sendForm(params)

                          is_send = true
                      }
                      
                      self.$emit('closeForm', is_send)
                    });
                });
                }
            });
        },
        acceptNumber() {
            let x = this.phone.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.phone = !x[2] ? x[1] : x[1] + '(' + x[2] + ') ' + x[3] + (x[4] ? '-' + x[4] : '');
        },
        sendForm(params) {
            const res = axios.post('/contactform', params);
        }
    }
}
</script>
<style>
  .title {
      font-weight: 500;
  }

  .v-application .Label {
    margin: 0;
    font-weight: 500;
  }

  .v-application .text-h6 {
    font-size: 1.2rem!important;
  }

  .col.input-block {
    padding-top: 0;
    padding-bottom: 2px;
  }

  .col.input-block.checkbox {
    padding: 0;
  }

  .col .v-input-slot {
    min-height: 48px!important;
    border-radius: 7px;
  }

  .hr {
    margin-bottom: 12px;
  }

  .hr hr {
      border: 1px solid #dfdfdf;
  }

  .v-card__text {
    padding-bottom: 0!important;
  }

  .v-card__title {
    padding-bottom: 0!important;
    padding-top: 0!important;
  }

  .v-application a {
    text-decoration: none;
  }

  .v-input.v-input--checkbox {
    margin-top: 0;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0!important;
  }

  .v-btn.v-btn--has-bg.theme--light.v-size--default {
    color: #fff;
  }
</style>